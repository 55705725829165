import React, { Component } from 'react'
import './Style.css';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

export default class Tab2 extends Component{
    constructor(){
        super();
        this.state={
          tab2:[],
          relatedoc:[],
          page_details:[],
          base_url:global.BaseUrl,
          
        }
      }
    
      componentDidMount(){
    
        fetch(`${this.state.base_url}/tab2`).then((resp)=>{
          resp.json().then((result)=>{
            //  console.warn(result);
            this.setState({ tab2: result});        
          })
        });
        fetch(`${this.state.base_url}/getfilesbyid/1`).then((resp)=>{
          resp.json().then((result)=>{
            this.setState({ relatedoc: result});        
          })
        });

        fetch(`${this.state.base_url}/tabdetails/1`).then((resp)=>{
          resp.json().then((result)=>{
            this.setState({ page_details: result});
                    
          })
        });

    }
    render(){

        const {tab2,relatedoc,page_details} = this.state;
      const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

        // console.log(tab2);
 
     
       return(
        <> 
 

           <section className="section-reservation-form">
              <div class="container">
                {
                    tab2.map(page_name=>
                        <div class="swin-sc swin-sc-title style-2">
                        <h3 class="title swin-sc-title"><span>{page_name.sub_page}</span></h3>
                     </div>
                        )
                }
                  </div>    
            </section>

            {
              page_details.map(details=>{
                return(
                  <section className="row p-4 ">
            <div className=" para">
       
          <div style={{width:'90%',justifyContent:'center',textAlign:'justify !important'}} className=" tab2_tab">
            <p style={{textAlign:'justify !important',fontFamily:'-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif'}}>{renderHTML(details.tab_description)}</p>
          </div>
      </div>    
            </section>
                )
              })
            }


    {
           relatedoc.length == 0 ? null :  
            <div className="row bg-color padding-bottom-100 related_doc">
           <div className="container">
           <h3 className="res-title" style={{color:'#000',marginTop:'40px'}}>Related Document</h3>
           <div className="row mb-3">
           <div className="col-12">
           { relatedoc.map(doc=>
             
             <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 ">
            <a href={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${doc.file_name}`} target="_blank"><p class="des realtedoc">{doc.file_title}</p></a>
             </div>
               ) }
           </div>
           </div> 
          
           </div>
           </div> 
         }

          
    
    </>



        ) }
       }
