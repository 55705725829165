import React, { Component } from 'react'
import './Style.css';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import './BaseUrl';
import Slider from "react-slick";

export default class Lobby_Reception extends Component{

  constructor(){
    super();
    this.state={
      banner_img:[],
      base_url:global.BaseUrl,
      
    }
  }

  componentDidMount(){
    fetch(`${this.state.base_url}/getBannerById/10`).then((resp)=>{
      resp.json().then((result)=>{
        //  console.log(result);
        this.setState({ banner_img: result.banner_images});
        // this.setState({ headingType: result.data});           
      })
    });
  }

    render(){
      const {banner_img} = this.state;

      var settings = {
        className: "",
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 7000,
        pauseOnHover: false,
        cssEase: 'linear',
        adaptiveHeight: true
        
      };

      
        

      // const photos = [{
      //   src: "assets/images/banner/LobbyReception_banner1.jpg",
      //   sizes: '(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px',
      //   caption: "",
      //   subcaption: "",
      //   thumbnail: "assets/images/banner/LobbyReception_banner1.jpg",
      // },{
      //   src: "assets/images/banner/LobbyReception_banner2.png",
      //   sizes: '(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px',
      //   caption: "",
      //   subcaption: "",
      //   thumbnail: "assets/images/banner/LobbyReception_banner2.png",
      // },
      // {
      //   src: "assets/images/banner/Lobbyreception_banner3.jpg",
      //   sizes: '(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px',
      //   caption: "",
      //   subcaption: "",
      //   thumbnail: "assets/images/banner/Lobbyreception_banner3.jpg",
      // },{
      //   src: "assets/images/banner/Lobbyreception_banner4.jpg",
      //   sizes: '(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px',
      //   caption: "",
      //   subcaption: "",
      //   thumbnail: "assets/images/banner/Lobbyreception_banner4.jpg",
      // }];

      // const photos =  banner_img.map((banner_image)=>{
      //     // const banner_image = this.state.baneer_new_images
      //     const imgurl = banner_image;
      //     this.state.baneer_new_images = [{
      //    src: `http://magma.ksoftpl.com/magma-backend/public/uploads/${imgurl}`,
      //    sizes: '(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px',
      //    caption: "",
      //    subcaption: "",
      //    thumbnail: "assets/images/banner/LobbyReception_banner1.jpg",
      //  }]
            
      // })
     

       
  
        
      
     
       return(
        <> 
        {/* <Carousel          
        className='framed-carousel'
       index={0}
        isLTR={true}
       isMaximized={false}
       hasSizeButton='topLeft'
       hasMediaButton='topCenter'
       hasIndexBoard='topRight'
       hasSizeButtonAtMax='bottomLeft'
       hasMediaButtonAtMax='bottomCenter'
       hasIndexBoardAtMax='bottomRight'
       hasCaptionsAtMax='top'
       hasThumbnails={false}
       hasThumbnailsAtMax={true}
       shouldMaximizeOnClick={true}
       shouldMinimizeOnClick={true}
       canAutoPlay={true}
       isAutoPlaying={true}
       autoPlayInterval	= {8000}
      //  autoPl={50}
        activeIcon={<span className='icon-text'>x</span>}
        passiveIcon={<span className='icon-text'>o</span>}
        images={this.state.baneer_new_images} /> */}
{/* 
<div style={{padding:'0px 20px 0px 20px'}}> 
        <h2>Fade</h2>
        <Slider {...settings}>
          
            {
              banner_img.map(single_image=>{
                return(
                  <div>
                  <img src={`http://192.168.1.29/magma-backend/public/uploads/${single_image}`} />
                  </div>
                )
              })
            }
         
      
        </Slider>
      </div> */}
      <div className="slider_images">
        
        <Slider {...settings}>
        {
              banner_img.map(single_image=>{
                return(
                  <div>
                  <img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${single_image}`} className="bnr_img" />
                  </div>
                )
              })
            }
         
        </Slider>
      </div>
 

        <section className="section-reservation-form ">
              <div className="container">
                    <div class="swin-sc swin-sc-title style-2">
                        <div className="swin-sc swin-sc-title style-2">
                        <h3 className="title swin-sc-title" style={{marginBottom:'20px'}}><span>Lobby Reception</span></h3>
                        <p className="cafe-text text_justify">The lobby entrance has been designed to provide a warm welcome and an inviting sense of arrival for all employees and visitors. The space strikes impressively with a stunning ambience, comfortable seating, and best in class hospitality to provide all visitors with a world class experience as they enter the facility.</p>

                        <ul style={{color:"#000",fontSize:'15px'}} className="align mt-4">
                          <li>The reception desk has dedicated, expert personnel to ensure a warm arrival experience</li>
                          <li>Robust visitor management process designed by leveraging technology to provide seamless entry/exit experience to visitors</li>
                        </ul>
                        
                      </div>
                   
                  </div>
               </div>   
            </section>

            <section className=" mb-5 p-btm">
          <div className="row ">
                
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 list_section m-top">
                    <ul style={{padding:'0px 0px 0px 0px !important'}} className="lobby_tab">
                      <li className="list_one">
                        <p><img src="assets/images/icon/commonground_squarelogo.png" width="100" height="100"/></p>
                        <ol className="list_one_list align" style={{listStyleType:'square',zIndex:'99'}}>
                          <li>Specially curated coffee blend</li>
                          <li>Warm, relaxed and collaborative ambience </li>
                          <li>Meticulously crafted menu by skilled baristas</li>
                        </ol>
                        <p>
                        Lobby Coffee Bar 
                        </p>
                        
                      </li>
                      <li className="list_two">
                        <p><img src="assets/images/icon/Visitor-Management_Logo3.jpg" width="100" height="100"/></p>
                        <ol className="list_two_list align" style={{listStyleType:'square',zIndex:'99'}}>
                        <li>Inviting sense of arrival and warm welcome</li>                          
                        <li>Dedicated, expert personnel for assistance</li>
                          <li>Leveraging technology to provide seamless visitor experience</li>
                        </ol>
                        <p>
                        {/* Visitor management  */}
                        Reception 
                        </p>
                        
                      </li>
                      <li className="list_three">
                      <p><img src="assets/images/icon/Community-Space-Logo3.jpg" width="100" height="100"/></p>
                      <ol className="list_three_list align" style={{listStyleType:'square',zIndex:'99'}}>
                      <li>Open space offering flexible setup options</li>                         
                       <li>Unique space for events and networking</li>
                         <li>Equipped with AV and technology</li>
                        </ol>
                        <p>
                        Community Space
                        </p>
                      </li>
                     
                      
                    </ul>
                </div>
          </div>
         
        </section>

        <section className="section-reservation-form p-btm">
              <div className="container">
                    <div class="swin-sc swin-sc-title style-2">
                        <div className="swin-sc swin-sc-title style-2">                       
                        <p className="cafe-text text_justify">In order to ensure a seamless visitor experience, all employees are requested to register their visitors at go/openspace. This would help to reduce their waiting time at the lobby reception and ensure a hassle free visitor experience.</p>

                        
                        
                      </div>
                   
                  </div>
               </div>   
            </section>
          {/* <h1>hello</h1>
            <section>
            {
                    banner_img.map((images2)=>{
                      console.log(images2)
                      return(
                       <>
                       <h1>hello</h1>
                       <img src={`http://192.168.1.29/magma-backend/public/uploads/${images2}`} />
                       </>
                      )
                    })
                  }
            </section> */}
    </>
        ) }
       }
