import React, { Component } from 'react';
import './Style.css';
import './BaseUrl';


class Nutrition extends Component {
  constructor(){
    super();
    this.state={
        base_url:global.BaseUrl,
    //   this.myRef = React.createRef()
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }
  

render() {

    const { dish_name,serving_per_container,counter_Servings,counter_Servedcup,counter_Servedi,counter_KiloCa,
        counter_TransFatval,counter_TransFatper,counter_SaturatedFatval,counter_SaturatedFatper,counter_TotalFatval,
        counter_TotalFatper,counter_CholesterolVal,counter_CholesterolPer,counter_SodiumVal,counter_SodiumPer,
        counter_TotalCarbohydrateVal,counter_TotalCarbohydratePer,counter_DietaryFiberVal,counter_DietaryFiberPer,counter_Sugar,
        counter_AddedSugarsPer,counter_AddedSugar,counter_Protei,counter_VitaminAVal,counter_VitaminAPer,counter_CalciumVal,counter_CalciumPer,counter_IronVal,
        counter_IronPer,counter_PotassiumPer,counter_PotassiumGVal} = this.props.location
    
  return (
    <div>
    <div className=" overflow-auto box-height bg-white" >
    
    <div id="nutritionfacts" ref={this.myRef}>
            <div>
                <h2 className="extra_large">Nutrition Facts</h2><hr/>
            </div>
           

            <div className="detials-section">
                <div className="large_heading">{dish_name}</div><hr />
                <div className="details"><span>{serving_per_container} </span>serving per container</div>
                <div className="d-flex justify-content-between">
                <div><span className="medium_heading">Serving size</span></div>
                <div><span className="medium_heading">{counter_Servedcup} cup ({counter_Servings}{counter_Servedi})</span></div>
                </div>
                <hr className="hr_10" />
                <div className="d-flex justify-content-between">
                    <div>
                            <div className="short_heading">Amount per serving</div>
                            <div className="large_heading">Calories</div>
                    </div>

                    <div>
                            <div className="extra_large">{counter_KiloCa}</div>
                    </div>
                </div>

                <hr className="hr_5" />

                <div className="short_heading text-right">% Daily Value*</div><hr />

                <div className="d-flex justify-content-between">
                    <div><span className="short_heading">Total Fat</span> <span className="details">{counter_TotalFatval}g</span></div>
                    <div><span className="short_heading">{counter_TotalFatper}%</span></div>
                </div><hr />

                <div className="d-flex justify-content-between left-space">
                    <div><span className="details">Saturated Fat</span> <span className="details">{counter_SaturatedFatval}g</span></div>
                    <div><span className="short_heading">{counter_SaturatedFatper}%</span></div>
                </div><hr />

                <div className="d-flex justify-content-between left-space">
                    <div><span className="details">Trans Fat</span> <span className="details">{counter_TransFatval}g</span></div>
                    <div><span className="short_heading">{counter_TransFatper}%</span></div>
                </div><hr />

                <div className="d-flex justify-content-between">
                    <div><span className="short_heading">Cholesterol</span> <span className="details">{counter_CholesterolVal}mg</span></div>
                    <div><span className="short_heading">{counter_CholesterolPer}%</span></div>
                </div><hr />

                <div className="d-flex justify-content-between">
                    <div><span className="short_heading">Sodium</span> <span className="details">{counter_SodiumVal}mg</span></div>
                    <div><span className="short_heading">{counter_SodiumPer}%</span></div>
                </div><hr />

                <div className="d-flex justify-content-between">
                    <div><span className="short_heading">Total Carbohydrates</span> <span className="details">{counter_TotalCarbohydrateVal}g</span></div>
                    <div><span className="short_heading">{counter_TotalCarbohydratePer}%</span></div>
                </div><hr />

                <div className="d-flex justify-content-between left-space">
                    <div><span className="details">Dietary Fiber</span> <span className="details">{counter_DietaryFiberVal}g</span></div>
                    <div><span className="short_heading">{counter_DietaryFiberPer}%</span></div>
                </div><hr />

                <div className="d-flex justify-content-between left-space">
                    <div><span className="details">Total Sugars </span> <span className="details">{counter_Sugar}g</span></div>
                    <div><span className="short_heading"></span></div>
                </div><hr style={{marginLeft:'40px'}} />

                <div className="d-flex justify-content-between left-space">
                    <div><span className="details" style={{marginLeft:'20px'}}>Included </span> <span className="details">{counter_AddedSugar}g Added Sugars</span></div>
                    <div><span className="short_heading">{counter_AddedSugarsPer}%</span></div>
                </div><hr />


                <div className="d-flex justify-content-between">
                    <div><span className="short_heading">Protein </span> <span className="details">{counter_Protei}g</span></div>
                    {/* <!-- <div><span className="short_heading">{{$nv->protein_p}}%</span></div> --> */}
                </div><hr className="hr_10" />

                <div className="d-flex justify-content-between">
                    <div><span className="details">Vitamin D </span> <span className="details">{counter_VitaminAVal}mcg</span></div>
                    <div><span className="details">{counter_VitaminAPer}%</span></div>
                </div><hr />

                <div className="d-flex justify-content-between">
                    <div><span className="details">Calcium </span> <span className="details">{counter_CalciumVal}mg</span></div>
                    <div><span className="details">{counter_CalciumPer}%</span></div>
                </div><hr />

                <div className="d-flex justify-content-between">
                    <div><span className="details">Iron </span> <span className="details">{counter_IronVal}mg</span></div>
                    <div><span className="details">{counter_IronPer}%</span></div>
                </div><hr />

                <div className="d-flex justify-content-between">
                    <div><span className="details">Potassium </span> <span className="details">{counter_PotassiumGVal}mg</span></div>
                    <div><span className="details">{counter_PotassiumPer}%</span></div>
                </div><hr className="hr_5" />

                <div>
                    <div className="footer_para">
                    * The % Daily Value (DV) tells you how much a nutrient in a serving of food contributes to a daily diet. 2,000 calories a day is used for general nutrition advice.
                    </div>
                </div>




            </div>

                         
               
    </div>
    
  </div>
  
</div>
  );
}
}

export default Nutrition;