import React, { Component } from 'react'
import './Style.css';
import Carousel from 'react-gallery-carousel';
import $ from 'jquery';
import 'react-gallery-carousel/dist/index.css';
import './BaseUrl';
import Slider from "react-slick";



export default class Overview extends Component{

  constructor(){
    super();
    this.state={
      model_data:[],
      banners:[],
      base_url:global.BaseUrl,
      banner_img:[],
      model_status:'window',

      
    }
  }

  componentDidMount(){

    fetch(`${this.state.base_url}/getAllPosts`).then((resp)=>{
      resp.json().then((result)=>{
        //  console.warn(result);
        this.setState({ model_data: result});
        
      })
    });



    fetch(`${this.state.base_url}/getBannerById/3`).then((resp)=>{
      resp.json().then((result)=>{
         console.warn(result);
        this.setState({ banners: result});
        
      })
    });

    fetch(`${this.state.base_url}/getBannerById/3`).then((resp)=>{
      resp.json().then((result)=>{
         console.log(result);
        this.setState({ banner_img: result.banner_images});
        // this.setState({ headingType: result.data});           
      })
    });


    $(document).ready(function() {    

      var id = '#dialog';
    
      //Get the screen height and width
      var maskHeight = $(document).height();
      var maskWidth = $(window).width();
    
      //Set heigth and width to mask to fill up the whole screen
      $('#mask').css({'width':maskWidth,'height':maskHeight});
      
      //transition effect		
      $('#mask').fadeIn(500);	
      $('#mask').fadeTo("slow",0.9);	
    
      //Get the window height and width
      var winH = $(window).height();
      var winW = $(window).width();
                
      //Set the popup window to center
      $(id).css('top',  winH/2-$(id).height()/2);
      $(id).css('left', winW/2-$(id).width()/2);
    
      //transition effect
      $(id).fadeIn(2000); 	
    
    //if close button is clicked
    $('.window .close').click(function (e) {
      //Cancel the link behavior
      e.preventDefault();
      
      $('#mask').hide();
      $('.window').hide();
    });		
    
    //if mask is clicked
    $('#mask').click(function () {
      $(this).hide();
      $('.window').hide();
    });		
    
  });

  

  }
    render(){
      const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
      const {model_data,banners,banner_img,model_status} = this.state;

      var settings = {
        className: "",
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        // autoplaySpeed: 7000,
        speed: 1000,
      autoplaySpeed: 7000,
      pauseOnHover: false,
      cssEase: 'linear',
      adaptiveHeight: true
        
        
      };
      
      // const photos = [{
      //   src: "assets/images/banner/overview_banner1.jpg",
      //   sizes: '(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px',
      //   caption: "",
      //   subcaption: "",
      //   thumbnail: "assets/images/banner/overview_banner1.jpg",
      // }]

      // const photos = [{
      //   src: "assets/images/banner/overview_banner1.jpg",
      //   sizes: '(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px',
      //   caption: "",
      //   subcaption: "",
      //   thumbnail: "assets/images/banner/overview_banner1.jpg",
      // },{
      //   src: "assets/images/banner/overview_banner2.jpg",
      //   sizes: '(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px',
      //   caption: "",
      //   subcaption: "",
      //   thumbnail: "assets/images/banner/overview_banner2.jpg",
      // },
      // {
      //   src: "assets/images/banner/Overview_banner_3.jpg",
      //   sizes: '(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px',
      //   caption: "",
      //   subcaption: "",
      //   thumbnail: "assets/images/banner/Overview_banner_3.jpg",
      // },{
      //   src: "assets/images/banner/overview_banner4-new.jpg",
      //   sizes: '(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px',
      //   caption: "",
      //   subcaption: "",
      //   thumbnail: "assets/images/banner/overview_banner4-new.jpg",
      // },{
      //   src: "assets/images/banner/overview_banner5.jpg",
      //   sizes: '(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px',
      //   caption: "",
      //   subcaption: "",
      //   thumbnail: "assets/images/banner/overview_banner5.jpg",
      // },{
      //   src: "assets/images/banner/overview_banner6.jpg",
      //   sizes: '(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px',
      //   caption: "",
      //   subcaption: "",
      //   thumbnail: "assets/images/banner/overview_banner6.jpg",
      // },{
      //   src: "assets/images/banner/overview_banner7.jpg",
      //   sizes: '(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px',
      //   caption: "",
      //   subcaption: "",
      //   thumbnail: "assets/images/banner/overview_banner7.jpg",
      // }];
     
       return(
        <> 
        {/* <Carousel          
        className='framed-carousel'
        
       index={0}
        isLTR={true}
       isMaximized={false}
       hasSizeButton='topLeft'
       hasMediaButton='topCenter'
       hasIndexBoard='topRight'
       hasSizeButtonAtMax='bottomLeft'
       hasMediaButtonAtMax='bottomCenter'
       hasIndexBoardAtMax='bottomRight'
       hasCaptionsAtMax='top'
       hasThumbnails={false}
       hasThumbnailsAtMax={true}
       shouldMaximizeOnClick={true}
       shouldMinimizeOnClick={true}
       canAutoPlay={true}
       isAutoPlaying	={true}
      //  autoPl={50}
      autoPlayInterval	= {8000}

      
        activeIcon={<span className='icon-text'>x</span>}
        passiveIcon={<span className='icon-text'>o</span>}
        images={photos} /> */}
  <div className="slider_images">
        
        <Slider {...settings}>
        {
              banner_img.map(single_image=>{
                return(
                  <div>
                  <img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${single_image}`} className="bnr_img" />
                  </div>
                )
              })
            }
         
        </Slider>
      </div>

           

      <div className="container detail_section">


      <p>
      {
         
         model_data.map(data=>

           this.model_status = data.status
          )
       }
       
      </p>

        
        
      <section className="row_section">
        <div id="boxes">
  <div style={{display: 'none',Top:'0px !important'}} id="dialog" className="window">
    <div id="lorem">
      <div className="row">
      
      <div className="col-md-11 col-6">
      
      </div>
     <div className="col-md-1 col-2 text-right">
     <a href="#" className="close agree " style={{textAlign:'right',marginLeft:'20px'}}>✖</a>
     </div>
      </div><hr/>
    {
      model_data.map(data=>
        <>
        <img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${data.post_image}`} className="img-fluid model_img" alt=""/>
        <p>{renderHTML(data.post_data)}</p>
        
        </>
        )
    }
      
    </div>
  </div>
  <div style={{width: 1478, fontSize: '32pt', color: 'white', height: 602, display: 'none', opacity: '0.8'}} id="mask" />
</div>
        </section>
        <section className="mt-5 mb-5">
          <div className="row p-top p-btm row_section">
              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 ">
                <h3 className="maintitle" style={{textAlign:'center'}}>OVERVIEW</h3>
                  <hr/>
                  <p className="text_justify">
                     Amenity Services aims to enhance the employee experience at the new campus in Hyderabad by utilizing best in class service model through innovation, modern technology and state of the art infrastructure.
                  </p>
              </div>
              {/* <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12 ">
                  <div className="card">
                      <div className="card-body">
                         <img src="assets/images/background/overview.jpg" alt className="img img-responsive" style={{width:"50em"}}/>
                      </div>
                  </div>
              </div> */}
          </div>
        </section>
        <section className="mt-5 mb-5">
          <div className="row p-top p-btm">
              <div className="col-lg-12">
                  <h3 className="maintitle" style={{textAlign:'center'}}>KEY CONSIDERATIONS</h3>
                  <hr />
                  
              </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 list_section m-top">
                    <ul style={{padding:'0px 0px 0px 0px !important',paddingInline:'20px'}}>
                      <li className="list_one">
                        <p><img src="assets/images/icon/FoodProgram_logo4.jpg" width="70" height="70"/></p>
                        <ol className="list_one_list align" style={{listStyleType:'square'}}>
                          <li>Wide variety of food choices and cuisines</li>
                          <li>Self-sustained in house kitchen</li>
                          
                        </ol>
                        <p>
                         Enhanced food program
                        </p>
                        
                      </li>
                      <li className="list_two">
                        <p><img src="assets/images/icon/HealthAndWellness_logo5.png" width="70" height="70"/></p>
                        <ol className="list_two_list align" style={{listStyleType:'square'}}>                         
                        {/* <li>Specially curated healthy dining menus</li> */}
                          <li>Chef curated menus focussing on overall well being</li>
                          <li>Inclusion of Superfoods in the menu items</li>
                        </ol>
                        <p>
                        Focus on health and wellness
                        </p>
                      </li>
                      <li className="list_three">
                      <p><img src="assets/images/icon/HygieneAndSanitation_logo_4.jpg" width="70" height="70"/></p>
                      <ol className="list_three_list align" style={{listStyleType:'square'}}>
                      <li>Periodic Hygiene Audits and Sample Collection</li>                         
                       <li>End to end process control for quality assurance</li>
                      {/* <li>Regular hygiene inspections</li> */}
                        </ol>
                        <p>
                        Enhanced Hygiene and Sanitation
                        </p>
                      </li>
                      <li className="list_four">
                      <p><img src="assets/images/icon/technology_logo3.jpg" width="70" height="70"/></p>
                      <ol className="list_four_list align" style={{listStyleType:'square'}}>
                      <li>Menu and order displays on LED’s</li>                         
                       <li>Self checkout payment kiosks</li>
                         <li>Sodexo card payments through Myworkspace app</li>
                         <li>Grab Scan & Go</li>
                        </ol>
                        <p>
                        Seamless integration of technology
                         </p>
                      </li>
                      <li className="list_five">
                      <p><img src="assets/images/icon/sustainability_logo3.jpg" width="70" height="70"/></p>
                      <ol className="list_five_list align" style={{listStyleType:'square'}}>
                      <li>Reduction of paper cups and plastic products</li>
                      {/* <li>Adoption of eco friendly packaging</li> */}
                      <li>Emphasis on sourcing of local and seasonal products</li>
                      {/* <li>Next gen equipment and reduction of carbon footprints</li> */}
                      <li>Recycling of dry and wet waste</li>
                      <li>In-house grown microgreens</li>
                        </ol>
                        <p>
                        Sustainability 
                        </p>
                      </li>
                    </ul>
                </div>
          </div>
          <hr/>
        </section>
        <section  className="mt-5 mb-5 row_section">
        <div className="row p-top p-btm">
              <div className="col-lg-12">
                 <p className="text_justify">
                 The food program aims to meet the daily food and beverage requirements of the employees including requests for working lunches, catering, events, etc.
                 </p>
              </div>
        </div>
        
        {/* //model  */}

        


        </section>




      </div>

  </>


       );       
    }
    
}
