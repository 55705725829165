import React, { Component } from 'react';

class Updates extends Component{
    render(){
        return(
            <>
             <div className="top-header top-slider">
  <div className="slides">
    <div className="slide-content slide-layout-01">
      <div className="swin-sc swin-sc-title"><img src="assets/images/slider/slider1-icon.png" data-ani-in="fadeInUp" data-ani-out="fadeOutDown" data-ani-delay={500} alt className="slide-icon animated fadeInUp" />
        <p data-ani-in="fadeInUp" data-ani-out="fadeOutDown" data-ani-delay={800} className="top-title animated fadeInUp"><span>Welcome to Updates</span></p>
        <h3 data-ani-in="fadeInUp" data-ani-out="fadeOutDown" data-ani-delay={1000} className="title animated fadeInUp">Let Enjoy The Rhym Of Fooday Dishes</h3>
      </div>
      <div data-ani-in="fadeInUp" data-ani-out="fadeOutDown" data-ani-delay={1200} className="btn-wrap text-center animated fadeInUp"><a href="javascript:void(0)" className="btn swin-btn"> <span>Read More</span></a></div>
    </div>
    <div className="slide-bg"><img src="assets/images/slider/slider1-bg1.jpg" alt className="img img-responsive" /></div>
  </div>
  <div className="slides">
    <div className="slide-content">
      <div className="swin-sc swin-sc-title"><img src="assets/images/slider/slider1-icon.png" data-ani-in="fadeInUp" data-ani-out="fadeOutDown" data-ani-delay={500} alt className="slide-icon animated" />
        <p data-ani-in="fadeInUp" data-ani-out="fadeOutDown" data-ani-delay={800} className="top-title animated"><span>Welcome to Healthy Recipes</span></p>
        <h3 data-ani-in="fadeInUp" data-ani-out="fadeOutDown" data-ani-delay={1000} className="title animated">Let Enjoy The Rhym Of Fooday Dishes</h3>
      </div>
      <div data-ani-in="fadeInUp" data-ani-out="fadeOutDown" data-ani-delay={1200} className="btn-wrap text-center animated"><a href="javascript:void(0)" className="btn swin-btn"> <span>Read More</span></a></div>
    </div>
    <div className="slide-bg"><img src="assets/images/slider/slider1-bg2.jpg" alt className="img img-responsive" /></div>
  </div>
</div>
            </>
        );
    }
}

export default Updates;
