import React,{Component} from 'react';
import MaterialTable from 'material-table';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import './Style.css';



export default class Chart_table extends Component{
  
  render(){
    return (
      <div>
        <MaterialTable
  columns={[
                {
                  title: 'Room Name', field: 'room_name',
                  customSort: (a, b) => a.room_name.length - b.room_name.length
                },
                { title: 'Room Dimension (ft)', field: 'room_dimension' }, 
                { title:'Sq.ft',field:'sq_ft'},   
                { title:'Theatre',field:'theater'},
                { title:'Theatre',field:'theater1'},
                { title:'Classroom',field:'class_room'},
                { title:'Classroom',field:'class_room1'},
                { title:'U-Shape',field:'u_shape'},
                { title:'U-Shape',field:'u_shape1'},
                { title:'Hollow Square',field:'hollow_square'},
                { title:'Hollow Square',field:'hollow_square1'},
                { title:'Clusters(6P)',field:'clusters'},
                { title:'Clusters(6P)',field:'clusters1'},
                { title:'Conference',field:'conference'},
                { title:'Conference',field:'conference1'},
                //{ title:'Rounds(72") (8p)',field:'rounds'},
                { title:'Rounds (TBA) (60") (5P)',field:'rounds_sec'},
                { title:'Rounds (TBA) (60") (5P)',field:'rounds_sec1'},
                { title:'Reception',field:'reception'},
                { title:'Reception',field:'reception1'},
  
              ]}
              data={[
                            { room_name: '2.2',room_dimension:'35 x 31',sq_ft:'1085', theater:84,theater1:17, class_room:40,class_room1:20,u_shape:22, u_shape1:10,hollow_square:32,hollow_square1:16,clusters:30, clusters1:12,conference:'-', conference1:'-',rounds_sec:25, rounds_sec1:'-',reception:72, reception1:36},
                            { room_name: '2.3',room_dimension:'35 x 31',sq_ft:'1085',theater:84, theater1:17 ,class_room:40,class_room1:20,u_shape:22, u_shape1:10,hollow_square:32, hollow_square1:16,clusters:30, clusters1:12,conference:'-', conference1:'-',rounds_sec:35, rounds_sec1:'-',reception:72 , reception1:36},
                            { room_name: '2.4',room_dimension:'19 x 35' ,sq_ft:'685', theater:'-', theater1:'-',class_room:'-',class_room1:'-',u_shape:'-', u_shape1:'-',hollow_square:'-', hollow_square1:'-',clusters:'-',clusters1:'-',conference:'24',conference1:10,rounds_sec:'-', rounds_sec1:'-',reception:'-', reception1:'-'},
                            { room_name: '2.5',room_dimension:'34 x 42' ,sq_ft:'1428', theater:104, theater1:20,class_room:40,class_room1:25,u_shape:28, u_shape1:13,hollow_square:36, hollow_square1:16,clusters:48,clusters1:15,conference:'-', conference1:'-',rounds_sec:'40', rounds_sec1:'-',reception:95, reception1:47},
                		  { room_name: '2.6',room_dimension:'35 x 42',sq_ft:'1470', theater:104, theater1:20,class_room:40, class_room1:25,u_shape:28, u_shape1:13,hollow_square:'36', hollow_square1:16,clusters:48, clusters1:15,conference:'-', conference1:'-',rounds_sec:40, rounds_sec1:'-',reception:98 , reception1:47},
                            { room_name: '2.5/2.6',room_dimension:'69 x 42' ,sq_ft:'2898', theater:'202*', theater1:'41*', class_room:'84*', class_room1:'48*', u_shape:'-',u_shape1:'-',hollow_square:'-', hollow_square1:'-',clusters:'78*', clusters1:'27*',conference:'-', conference1:'-',rounds_sec:'75*', rounds_sec1:'-',reception:193 , reception1:96},
                            { room_name: '2.7',room_dimension:'35 x 42' ,sq_ft:'1470', theater:104, theater1:20, class_room:40,class_room1:25, u_shape:28, u_shape1:13,hollow_square:36, hollow_square1:16,clusters:48,clusters1:15,conference:'-', conference1:'-',rounds_sec:40, rounds_sec1:'-',reception:98, reception1:47},
                		  // { room_name: '2.6/2.7',room_dimension:'71 x 42' ,sq_ft:'2982', theater:'202*',  theater1:'41*',class_room:'84*', class_room1:'48*',u_shape:'-', u_shape1:'-',hollow_square:'-', hollow_square1:'-',clusters:'78', clusters1:'27*',conference:'-', conference1:'-',rounds_sec:'75*', rounds_sec1:'-',reception:199 , reception1:99},
                		  // { room_name: '2.5/2.6/2.7',room_dimension:'105 x 42' ,sq_ft:'4410', theater:'320*',  theater1:'66*',class_room:'136*', class_room1:'70*',u_shape:'-', u_shape1:'-',hollow_square:'-', hollow_square1:'-',clusters:'132*', clusters1:'45*',conference:'-', conference1:'-',rounds_sec:'130*', rounds_sec1:'-',reception:294, reception1:147},
                            { room_name: '2.8',room_dimension:'37 x 42',sq_ft:'1554', theater:104, theater1:20,class_room:40,class_room1:25,u_shape:28, u_shape1:13,hollow_square:36, hollow_square1:16,clusters:48, clusters1:15,conference:'-', conference1:'-',rounds_sec:40, rounds_sec1:'-',reception:103, reception1:51},
                		  { room_name: '2.7/2.8',room_dimension:'73 x 42',sq_ft:'3066', theater:'202*', theater1:'41*',class_room:'84*',class_room1:'48*',u_shape:'-',u_shape1:'-',hollow_square:'-',hollow_square1:'-',clusters:'78*', clusters1:'27*',conference:'-', conference1:'-',rounds_sec:'75*', rounds_sec1:'-',reception:204, reception1:102},
                		  // { room_name: '2.6/2.7/2.8',room_dimension:'109 x 42',sq_ft:'4578', theater:'320*', theater1:'66*',class_room:'136*', class_room1:'70*',u_shape:'-', u_shape1:'-',hollow_square:'-', hollow_square1:'-',clusters:'132*', clusters1:'45*',conference:'-', conference1:'-',rounds_sec:'130*', rounds_sec1:'-',reception:305, reception1:125},
                		  { room_name: '2.5/2.6/2.7/2.8',room_dimension:'143 x 42' ,sq_ft:'6006', theater:'408*', theater1:'89*',class_room:'180*', class_room1:'98*',u_shape:'-', u_shape1:'-',hollow_square:'-',hollow_square1:'-',clusters:'180*', clusters1:'69*',conference:'-', conference1:'-',rounds_sec:'175*', rounds_sec1:'-',reception:400, reception1:200},              
                          ]}
                          options={{
                                        sorting: true,
                                        exportButton:true,
                                        showTitle: false,
                                        paging:false,
                                        headerStyle: {
                                          height: 10,
                                          maxHeight: 10,
                                          fontSize:'14px',
                                          textOverflow:'ellipsis',
                                          overflow:'hidden',
                                          fontWeight:'bold'
                                          }
                                      }}
            components={{
              Header: props => {
                return (
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={1} align="center">Room Name</TableCell>
                      <TableCell colSpan={1} align="center">Room Dimensions</TableCell>
                      <TableCell colSpan={1} align="center">Sq Ft</TableCell>
                      <TableCell colSpan={2} align="center">
                       Theatre
                      </TableCell>
                      <TableCell colSpan={2} align="center">
                        Classroom
                      </TableCell>
                      <TableCell colSpan={2} align="center">
                        U-Shape
                      </TableCell>
                      <TableCell colSpan={2} align="center">
                      Hollow Square
                      </TableCell>
                      <TableCell colSpan={2} align="center">
                      Clusters(6P)
                      </TableCell>
                      <TableCell colSpan={2} align="center">
                      Conference
                      </TableCell>
                      <TableCell colSpan={2} align="center">
                      Rounds (TBA) (60") (5P)
                      </TableCell>
                      <TableCell colSpan={2} align="center">
                      Reception
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                    </TableRow>
                  </TableHead>
                );
              },
              Row: ({ data }) => {
                return (
                  <TableRow>
                    <TableCell align="center">{data.room_name}</TableCell>
                    <TableCell align="center">{data.room_dimension}</TableCell>
                    <TableCell align="center">{data.sq_ft}</TableCell>
                    <TableCell align="center">{data.theater}</TableCell>
                    <TableCell align="center">{data.theater1}</TableCell>
                    <TableCell align="center">{data.class_room}</TableCell>
                    <TableCell align="center">{data.class_room1}</TableCell>
                    <TableCell align="center">{data.u_shape}</TableCell>
                    <TableCell align="center">{data.u_shape1}</TableCell>
                    <TableCell align="center">{data.hollow_square}</TableCell>
                    <TableCell align="center">{data.hollow_square1}</TableCell>
                    <TableCell align="center">{data.clusters}</TableCell>
                    <TableCell align="center">{data.clusters1}</TableCell>
                    <TableCell align="center">{data.conference}</TableCell>
                    <TableCell align="center">{data.conference1}</TableCell>
                    <TableCell align="center">{data.rounds_sec}</TableCell>
                    <TableCell align="center">{data.rounds_sec1}</TableCell>
                    <TableCell align="center">{data.reception}</TableCell>
                    <TableCell align="center">{data.reception1}</TableCell>
                  </TableRow>
                );
              }
            }}
          />
                </div>
              )
            }
  

  //   render(){
  //       return(
  //           <div style={{fontSize:'14px'}}>
  //               <MaterialTable
  //           title=""
  //           columns={[
  //             {
  //               title: 'Room Name', field: 'room_name',
  //               customSort: (a, b) => a.room_name.length - b.room_name.length
  //             },
  //             { title: 'Room Dimension (ft)', field: 'room_dimension' }, 
  //             { title:'Sq.ft',field:'sq_ft'},   
  //             { title:'Theatre',field:'theater'},
  //             { title:'Classroom',field:'class_room'},
  //             { title:'U-Shape',field:'u_shape'},
  //             { title:'Hollow Square',field:'hollow_square'},
  //             { title:'Clusters(6P)',field:'clusters'},
  //             { title:'Conference',field:'conference'},
  //             //{ title:'Rounds(72") (8p)',field:'rounds'},
  //             { title:'Rounds (TBA) (60") (5P)',field:'rounds_sec'},
  //             { title:'Reception',field:'reception'},

  //           ]}
  //           data={[
  //             { room_name: '2.2',room_dimension:'35 x 31',sq_ft:'1085', theater:84,class_room:40,u_shape:22,hollow_square:32,clusters:30,conference:'-',rounds_sec:'',reception:72},
  //             { room_name: '2.3',room_dimension:'35 x 31',sq_ft:'1085',theater:84,class_room:40,u_shape:22,hollow_square:32,clusters:30,conference:'-',rounds_sec:'',reception:72},
  //             { room_name: '2.4',room_dimension:'19 x 35' ,sq_ft:'685', theater:'-',class_room:'-',u_shape:'-',hollow_square:'-',clusters:'-',conference:'24',rounds_sec:'',reception:''},
  //             { room_name: '2.5',room_dimension:'34 x 42' ,sq_ft:'1428', theater:104,class_room:40,u_shape:28,hollow_square:36,clusters:48,conference:'-',rounds_sec:'',reception:95},
	// 		  { room_name: '2.6',room_dimension:'35 x 42',sq_ft:'1470', theater:104,class_room:40,u_shape:28,hollow_square:'36',clusters:48,conference:'-',rounds_sec:'',reception:98},
  //             { room_name: '2.5/2.6',room_dimension:'69 x 42' ,sq_ft:'2898', theater:'202*',class_room:'84*',u_shape:'-',hollow_square:'-',clusters:'78*',conference:'-',rounds_sec:'',reception:193},
  //             { room_name: '2.7',room_dimension:'35 x 42' ,sq_ft:'1470', theater:104,class_room:40,u_shape:28,hollow_square:36,clusters:48,conference:'-',rounds_sec:'',reception:98},
	// 		  { room_name: '2.6/2.7',room_dimension:'71 x 42' ,sq_ft:'2982', theater:'202*',class_room:'84*',u_shape:'-',hollow_square:'-',clusters:'78',conference:'-',rounds_sec:'',reception:199},
	// 		  { room_name: '2.5/2.6/2.7',room_dimension:'105 x 42' ,sq_ft:'4410', theater:'320*',class_room:'136*',u_shape:'-',hollow_square:'-',clusters:'132*',conference:'-',rounds_sec:'',reception:294},
  //             { room_name: '2.8',room_dimension:'37 x 42',sq_ft:'1554', theater:104,class_room:40,u_shape:28,hollow_square:36,clusters:48,conference:'-',rounds_sec:'',reception:103},
	// 		  { room_name: '2.7/2.8',room_dimension:'73 x 42',sq_ft:'3066', theater:'202*',class_room:'84*',u_shape:'-',hollow_square:'-',clusters:'78*',conference:'-',rounds_sec:'',reception:204},
	// 		  { room_name: '2.6/2.7/2.8',room_dimension:'109 x 42',sq_ft:'4578', theater:'320*',class_room:'136*',u_shape:'-',hollow_square:'-',clusters:'132*',conference:'-',rounds_sec:'',reception:305},
	// 		  { room_name: '2.5/2.6/2.7/2.8',room_dimension:'143 x 42' ,sq_ft:'6006', theater:'408*',class_room:'180*',u_shape:'-',hollow_square:'-',clusters:'180*',conference:'-',rounds_sec:'',reception:400},              
  //           ]}
  //           options={{
  //             sorting: true,
  //             exportButton:true,
  //             paging:false,
  //             headerStyle: {
  //               height: 10,
  //               maxHeight: 10,
  //               fontSize:'14px',
  //               textOverflow:'ellipsis',
  //               overflow:'hidden',
  //               fontWeight:'bold'
  //               }
  //           }}
  //         />
  //           </div>

            
  // )
        
    // }
}