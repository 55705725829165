import React,{Component} from 'react';
import './BaseUrl';
import './Style.css';
import $ from 'jquery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from 'react-router-dom';

class CounterMenu extends Component {


    constructor(){
        super();
        this.state={
          counter_data:[],
         counter_details:[],
         counter_images:[],
          headingType:[],
          my_id:[],
          base_url:global.BaseUrl,
          
        }
      }
    
       
    
      componentDidMount(){

       
     
        $(document).ready(function(){
	
          $('ul.tabs li').click(function(){
            var tab_id = $(this).attr('data-tab');
        
            $('ul.tabs li').removeClass('current');
            $('.tab-content').removeClass('current');
        
            $(this).addClass('current');
            $("#"+tab_id).addClass('current');
          })
        
        });
    
       

        fetch(`${this.state.base_url}/getByMenu/${this.props.match.params.id}`).then((resp)=>{
          resp.json().then((result)=>{
            //  console.log(result.counter);
            this.setState({ counter_details: result.counter});
            // this.setState({ headingType: result.data});           
          })
        });

        fetch(`${this.state.base_url}/getByMenu/${this.props.match.params.id}`).then((resp)=>{
          resp.json().then((result)=>{
            //  console.log(result.counter_data.categories);
            this.setState({ counter_data: result.counter_data});
            // this.setState({ headingType: result.data});           
          })
        });

        fetch(`${this.state.base_url}/getByMenu/${this.props.match.params.id}`).then((resp)=>{
          resp.json().then((result)=>{
            //  console.log(result.counter_data);
            this.setState({ headingType: result.counter_data});
            // this.setState({ headingType: result.data});           
          })
        });

        fetch(`${this.state.base_url}/getByMenu/${this.props.match.params.id}`).then((resp)=>{
          resp.json().then((result)=>{
            //  console.log(result.counter_data);
            this.setState({ counter_images: result.counter_images[0]});
            // this.setState({ headingType: result.data});         
            // console.log(result.counter_images);  
          })
        });
        
     

    }

    render(){
        const {counter_data,headingType,counter_details,counter_images} = this.state;
    
     
        return(
            <>
        <section className="about-us-session padding-top-100" >
  <div className="container-fluid">
     {
       
     }
            <div className="row">
            {/* <div className="col-md-4 col-sm-12">
                <img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${counter_details.counter_logo}`} alt className="img img-responsive wow zoomIn" style={{visibility: 'visible', animationName: 'zoomIn'}} />
                </div>
            <div className="col-md-8 col-sm-12">
              <div className="swin-sc swin-sc-title style-4 margin-bottom-20">
                 <h3 className="title">{counter_details.counter_name}</h3>
              </div>
              <p className="des margin-bottom-20 text-center" style={{fontSize:'15px'}}>{counter_details.counter_description}</p>
       
            </div> */}
            {/* <div className="col-md-1"></div> */}


            <div className="col-md-4 col-12 counter_top_img">
            <img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${counter_details.counter_logo}`} alt className="counter_top_content img img-responsive wow zoomIn" style={{visibility: 'visible', animationName: 'zoomIn'}} />
            </div>
           
            
            {/* {
              
              counter_images.map(image=>{
                return(
                 <div className="col-md-4 col-12  counter_top_img menu_img">
                   <img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${image}`} alt className="counter_top_content img img-responsive wow zoomIn counter_img" style={{visibility: 'visible', animationName: 'zoomIn',maxHeight:'212px !important',width:'100%'}} />
                 </div>
                )
              })
            } */}

                 <div className="col-md-4 col-12  counter_top_img menu_img">
                   <img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${counter_images}`} alt className="counter_top_content img img-responsive wow zoomIn counter_img" style={{visibility: 'visible', animationName: 'zoomIn',maxHeight:'212px !important',width:'100%'}} />
                 </div>
      

            <div className="col-md-4 col-12  counter_top_img ">
              {/* <p>Menu Indicator</p> */}
              <div className="counter_top_content" style={{background:'#fff',padding:'0px 10px 0px 10px',border:'1px solid #48368e',boxShadow:'0.5rem 1rem rgba(0,0,0,.15)!important'}}>
              <ui >
                <div style={{textAlign:'center'}}><b>Menu Indicator</b></div>
                <div><span><img src="../assets/images/icon/vag.png" width="20px" /></span> <span style={{fontSize:'12px',color:'#000'}}>Vegetarian Preparation</span></div>
                <div><span><img src="../assets/images/icon/non-vag-new.png" width="20px" /></span> <span style={{fontSize:'12px',color:'#000'}}>Non Vegetarian Preparation</span></div>
                <div><span><img src="../assets/images/icon/0.jpg" width="20px" /></span> <span style={{fontSize:'12px',color:'#000'}}>Contains cereals containing gluten</span></div>
                <div><span><img src="../assets/images/icon/1.jpg" width="20px" /></span> <span style={{fontSize:'12px',color:'#000'}}>Contains nuts</span></div>
                <div><span><img src="../assets/images/icon/2.jpg" width="20px" /></span> <span style={{fontSize:'12px',color:'#000'}}>Contains crustacean or their products</span></div>
                <div><span><img src="../assets/images/icon/3.jpg" width="20px" /></span> <span style={{fontSize:'12px',color:'#000'}}>Contains milk or their products</span></div>
                <div><span><img src="../assets/images/icon/4.jpg" width="20px" /></span> <span style={{fontSize:'12px',color:'#000'}}>Contains eggs or their products</span></div>
                <div><span><img src="../assets/images/icon/5.jpg" width="20px" /></span> <span style={{fontSize:'12px',color:'#000'}}>Contains fish or their products</span></div>
                <div><span><img src="../assets/images/icon/6.jpg" width="20px" /></span> <span style={{fontSize:'12px',color:'#000'}}>Contains groundnut, tree nut or their products</span></div>
                <div><span><img src="../assets/images/icon/7.jpg" width="20px" /></span> <span style={{fontSize:'12px',color:'#000'}}>Contains Soya or their products</span></div>
                <div><span><img src="../assets/images/icon/8.jpg" width="20px" /></span> <span style={{fontSize:'12px',color:'#000'}}>Contains sulphites in concentrations of 10mg/kg or more</span></div>
              </ui>
              </div>
            </div>

            {/* <div className="col-md-1"></div> */}

            
          </div>
          
  </div>
</section>

     {
       headingType.map(heading=>{
        // console.log("hello" , heading.id);
         this.state.my_id = heading.id;
          return(
            <h1></h1>  
          )
        })
     }

     
   

      {
       this.state.my_id == 8 ?
<section className="container my_div padding-top-50" style={{marginTop:'50px'}}>
<div className="swin-sc swin-sc-title  light">
          <p className="top-title"><span>All Day</span></p>
          <h3 className="title" style={{color:'#000'}}>Weekly  Special</h3>
        </div>
     {
      counter_data.map((mydata)=>{
        const category_list = mydata.categories;
        return(
          <div className="text-left">
            {
              category_list.map((supercat_name)=>{
                
                const sub_catategory = supercat_name.subcategories
                
                return(
                  <div style={{padding:'10px',marginTop:'20px'}} >
                  <h1 style={{color:'gray',textAlign:'center'}}>{supercat_name.supercatname}</h1>
                  {
                    sub_catategory.map((sub_cat)=>{
                      const dishes = sub_cat.category_dishes;
                      return(
                        <div style={{background:'#fff',padding:'10px'}}>
                          {/* <h3 className="sub_cat_heading">{sub_cat.subcatname}</h3> */}

                          {
                            sub_cat.subcatname == "NA" ?  <h3></h3> : <h3 className="sub_cat_heading">{sub_cat.subcatname}</h3> 
                          }
                        <div className="container">
                        <div className="row">
                          {
                            dishes.map((dish_list)=>{
                             return(
                               
                              <div className="col-lg-6 col-md-6" style={{marginBottom:'20px'}}>                
                              <div className="swin-sc products-01 style-04 light swin-vetical-slider">
                                

                                <div >
                                <div className="row">

                                <div className="col-md-9 d-flex">
                                                                  {/* <img src="https://cdn.iconscout.com/icon/free/png-512/non-veg-food-1851631-1569356.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />  */}

                                {
                                  dish_list.dish_type == "Veg" ?
                                  <img src="../assets/images/icon/vag.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                  :<img src="../assets/images/icon/non-vag-new.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                }
                                <p className="dish_name" style={{float:'left',marginRight:'5px'}}><span className="dish_line_height">{dish_list.dish_name}</span>  <span className="line_height10"> <span className="kcal_g">{dish_list.dish_KiloCalories == null ? "" : <span>({dish_list.dish_KiloCalories} Kcal) / ({dish_list.dish_ServingSize}{dish_list.dish_ServedIn})</span> }</span> 
                                  {
                                  dish_list.dish_indicator1 == null ? null :<div className="indicator_icon"> <span><img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${dish_list.dish_indicator1}`} className="dish_icon indicator_img" id="new_tab" style={{marginLeft:'5px',marginTop:'0px'}} /> </span>  </div>
                                }
                                
                                {
                                  dish_list.dish_indicator2 == null ? null :<div className="indicator_icon"><span><img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${dish_list.dish_indicator2}`} className="dish_icon" style={{marginLeft:'5px',marginTop:'0px'}} /></span>  </div>
                                }
                                {
                                  dish_list.dish_indicator3 == null ? null :<div className="indicator_icon"><span><img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${dish_list.dish_indicator3}`} className="dish_icon" style={{marginLeft:'5px',marginTop:'0px'}} /></span>  </div>
                                }
                                </span>
                                {/* <div className="indicator_icon_text">tootip</div>  */}
                                </p>
                                {/* <button type="button" class="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Tooltip on top">
  Tooltip on top
</button>
                                 */}
                                <p className="item-right dish_name text-center">{dish_list.dish_price == null ? null : <span className="price woocommerce-Price-amount amount">₹{dish_list.dish_price}</span>}</p>
                               
                                </div>
                                 <div class="col-md-2 col-2 p-0 m-auto">
                                {dish_list.dish_KiloCalories == null ? "" : <Link class="read_more_btn" to={{ pathname: "/Nutrition", dish_name:dish_list.dish_name,serving_per_container: dish_list.dish_ServingPerContainer, counter_Servedcup: dish_list.dish_ServedCup,
                                                                             counter_Servings: dish_list.dish_ServingSize, counter_Servedi: dish_list.dish_ServedIn, counter_KiloCa: dish_list.dish_KiloCalories,
                                                                              counter_TransFatval: dish_list.dish_TotalFatValue, counter_TransFatper: dish_list.dish_TransFatPercent,counter_SaturatedFatper:dish_list.dish_SaturatedFatPercent,
                                                                              counter_SaturatedFatval:dish_list.dish_SaturatedFatValue,counter_TotalFatval:dish_list.dish_TotalFatValue,counter_TotalFatper:dish_list.dish_TotalFatPercent,
                                                                              counter_CholesterolVal:dish_list.dish_CholesterolValue,counter_CholesterolPer:dish_list.dish_CholesterolPercent,counter_SodiumVal:dish_list.dish_SodiumValue,
                                                                              counter_SodiumPer:dish_list.dish_SodiumPercent,counter_TotalCarbohydrateVal:dish_list.dish_TotalCarbohydrateValue,counter_TotalCarbohydratePer:dish_list.dish_TotalCarbohydratePercent,
                                                                              counter_DietaryFiberVal:dish_list.dish_DietaryFiberValue,counter_DietaryFiberPer:dish_list.dish_DietaryFiberPercent,counter_Sugar:dish_list.dish_Sugars,
                                                                              counter_AddedSugar:dish_list.dish_AddedSugars,counter_AddedSugarsPer:dish_list.dish_AddedSugarsPercent,counter_Protei:dish_list.dish_Protein,counter_VitaminAVal:dish_list.dish_VitaminAValue,
                                                                              counter_VitaminAPer:dish_list.dish_VitaminAPercent,counter_IronPer:dish_list.dish_IronPercent,counter_IronVal:dish_list.dish_IronValue,
                                                                              dish_CalciumPercent:dish_list.counter_CalciumPer,counter_CalciumVal:dish_list.dish_CalciumValue,
                                                                              counter_PotassiumGVal:dish_list.dish_PotassiumGValue,counter_PotassiumPer:dish_list.dish_PotassiumPercent}}>Read More</Link>}
                                  
                                  </div>



                                </div>
                                
                                </div>
                                
                                  
                              </div>
                              <div className="des" style={{lineHeight:'0.1'}}>{dish_list.dish_description}</div>
                            </div>
                               
                             )
                            })
                          }
                          </div>
                        </div>
                        </div>
                      )
                    })
                  }
                  </div>
                )
              })
            }
          </div>
        )
      })
          
        
     }
</section>
 :
 <section className="my_div padding-top-50" style={{marginTop:'50px'}}>
<div className="swin-sc swin-sc-title text-center light ">
          <p className="top-title"><span>Weekly Change</span></p>
          <h3 className="title" style={{color:'#000'}}>Daily Special</h3>
  </div>

      <div className="container">
        <div className="row">
        <section  className="about-us-session padding-top-100 padding-bottom-100 container">
<div className="container ">
  <div style={{justifyContent:'center',display:'flex'}}>
  <ul className="tabs">
     


    <li className="tab-link tabs-btn current active swin-btn" style={{float:'left',marginLeft:'20px'}} data-tab="Monday">Monday</li>
    <li className="tab-link tabs-btn swin-btn" style={{float:'left',marginLeft:'20px'}} data-tab="Tuesday">Tuesday</li>
    <li className="tab-link tabs-btn swin-btn" style={{float:'left',marginLeft:'20px'}} data-tab="Wednesday">Wednesday</li>
    <li className="tab-link tabs-btn swin-btn" style={{float:'left',marginLeft:'20px'}} data-tab="Thursday">Thursday</li>
    <li className="tab-link tabs-btn swin-btn" style={{float:'left',marginLeft:'20px'}} data-tab="Friday">Friday</li>
    {/* <li className="tab-link tabs-btn swin-btn" style={{float:'left',marginLeft:'20px'}} data-tab="Saturday">Saturday</li>
    <li className="tab-link tabs-btn swin-btn" style={{float:'left',marginLeft:'20px'}} data-tab="Sunday">Sunday</li> */}



  </ul>
  </div>
  <div id="Monday" className="tab-content current active">
  {
      counter_data.filter(mydata=> mydata.name == "Monday" ).map(mydata=>{
        const category_list = mydata.categories;
        return(
          <div className="text-left">
            {
              category_list.map((supercat_name)=>{
                const sub_catategory = supercat_name.subcategories;
                
                return(
                  <div style={{padding:'10px',marginTop:'20px'}} >
                  <h1 style={{color:'gray',textAlign:'center'}}>{supercat_name.supercatname}</h1>
                  {
                    sub_catategory.map((sub_cat)=>{
                      const dishes = sub_cat.category_dishes;
                      return(
                        <div style={{background:'#fff',padding:'10px'}}>
                          {/* <h3 className="sub_cat_heading">{sub_cat.subcatname}</h3> */}
                          {
                            sub_cat.subcatname == "NA" ?  <h3></h3> : <h3 className="sub_cat_heading">{sub_cat.subcatname}</h3> 
                          }
                        <div className="container">
                        <div className="row">
                          {
                            dishes.filter(dish_list=>dish_list.counter_heading== 1).map((dish_list,index)=>{
                             return(
                               
                              <div className="col-lg-6 col-md-6" style={{marginBottom:'20px'}}>                
                              <div className="swin-sc products-01 style-04 light swin-vetical-slider">
                                

                                <div >
                                <div className="row">

                                <div className="col-md-9 d-flex">
                               
                                {/* <img src="https://cdn.iconscout.com/icon/free/png-512/non-veg-food-1851631-1569356.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />  */}
                                {
                                  dish_list.dish_type == "Veg" ?
                                  <img src="../assets/images/icon/vag.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                  :<img src="../assets/images/icon/non-vag-new.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                }
                                 <p className="dish_name" style={{float:'left',marginRight:'5px'}}><span className="dish_line_height">{dish_list.dish_name}</span>  <span className="line_height10"> <span className="kcal_g">{dish_list.dish_KiloCalories == null ? "" : <span>({dish_list.dish_KiloCalories} Kcal) / ({dish_list.dish_ServingSize}{dish_list.dish_ServedIn})</span> }</span> 
                                  {
                                  dish_list.dish_indicator1 == null ? null :<div className="indicator_icon"> <span><img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${dish_list.dish_indicator1}`} className="dish_icon indicator_img" id="new_tab" style={{marginLeft:'5px',marginTop:'0px'}} /> </span>  </div>
                                }
                                
                                {
                                  dish_list.dish_indicator2 == null ? null :<div className="indicator_icon"><span><img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${dish_list.dish_indicator2}`} className="dish_icon" style={{marginLeft:'5px',marginTop:'0px'}} /></span>  </div>
                                }
                                {
                                  dish_list.dish_indicator3 == null ? null :<div className="indicator_icon"><span><img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${dish_list.dish_indicator3}`} className="dish_icon" style={{marginLeft:'5px',marginTop:'0px'}} /></span>  </div>
                                }
                                </span>
                                {/* <div className="indicator_icon_text">tootip</div>  */}
                                </p>
                                
                                <p className="item-right dish_name text-center">{dish_list.dish_price == null ? null : <span className="price woocommerce-Price-amount amount">₹{dish_list.dish_price}</span>}</p>
                                </div>
                                <div class="col-md-2 col-2 p-0 m-auto">
                                {dish_list.dish_KiloCalories == null ? "" : <Link class="read_more_btn" to={{ pathname: "/Nutrition", dish_name:dish_list.dish_name,serving_per_container: dish_list.dish_ServingPerContainer, counter_Servedcup: dish_list.dish_ServedCup,
                                                                             counter_Servings: dish_list.dish_ServingSize, counter_Servedi: dish_list.dish_ServedIn, counter_KiloCa: dish_list.dish_KiloCalories,
                                                                              counter_TransFatval: dish_list.dish_TotalFatValue, counter_TransFatper: dish_list.dish_TransFatPercent,counter_SaturatedFatper:dish_list.dish_SaturatedFatPercent,
                                                                              counter_SaturatedFatval:dish_list.dish_SaturatedFatValue,counter_TotalFatval:dish_list.dish_TotalFatValue,counter_TotalFatper:dish_list.dish_TotalFatPercent,
                                                                              counter_CholesterolVal:dish_list.dish_CholesterolValue,counter_CholesterolPer:dish_list.dish_CholesterolPercent,counter_SodiumVal:dish_list.dish_SodiumValue,
                                                                              counter_SodiumPer:dish_list.dish_SodiumPercent,counter_TotalCarbohydrateVal:dish_list.dish_TotalCarbohydrateValue,counter_TotalCarbohydratePer:dish_list.dish_TotalCarbohydratePercent,
                                                                              counter_DietaryFiberVal:dish_list.dish_DietaryFiberValue,counter_DietaryFiberPer:dish_list.dish_DietaryFiberPercent,counter_Sugar:dish_list.dish_Sugars,
                                                                              counter_AddedSugar:dish_list.dish_AddedSugars,counter_AddedSugarsPer:dish_list.dish_AddedSugarsPercent,counter_Protei:dish_list.dish_Protein,counter_VitaminAVal:dish_list.dish_VitaminAValue,
                                                                              counter_VitaminAPer:dish_list.dish_VitaminAPercent,counter_IronPer:dish_list.dish_IronPercent,counter_IronVal:dish_list.dish_IronValue,
                                                                              dish_CalciumPercent:dish_list.counter_CalciumPer,counter_CalciumVal:dish_list.dish_CalciumValue,
                                                                              counter_PotassiumGVal:dish_list.dish_PotassiumGValue,counter_PotassiumPer:dish_list.dish_PotassiumPercent}}>Read More</Link>}
                                  
                                  </div>
                                </div>                                
                                </div>

                              </div>
                              <div className="des" style={{lineHeight:'0.1'}}>{dish_list.dish_description}</div>
                            </div>
                               
                             )
                            })
                          }
                          </div>
                        </div>
                        </div>
                      )
                    })
                  }
                  </div>
                )
              })
            }
          </div>
        )
      })
          
        
     }
  </div>





  <div id="Tuesday" className="tab-content ">
  {
      counter_data.filter(mydata=> mydata.name == "Tuesday" ).map(mydata=>{
        const category_list = mydata.categories;
        return(
          <div className="text-left">
            {
              category_list.map((supercat_name)=>{
                const sub_catategory = supercat_name.subcategories
                
                return(
                  <div style={{padding:'10px',marginTop:'20px'}} >
                  <h1 style={{color:'gray',textAlign:'center'}}>{supercat_name.supercatname}</h1>
                  {
                    sub_catategory.map((sub_cat)=>{
                      const dishes = sub_cat.category_dishes;
                      return(
                        <div style={{background:'#fff',padding:'10px'}}>
                           {
                            sub_cat.subcatname == "NA" ?  <h3></h3> : <h3 className="sub_cat_heading">{sub_cat.subcatname}</h3> 
                          }
                        <div className="container">
                        <div className="row">
                          {
                            dishes.filter(dish_list=>dish_list.counter_heading== 2).map((dish_list,index)=>{
                             return(
                               
                              <div className="col-lg-6 col-md-6" style={{marginBottom:'20px'}}>                
                              <div className="swin-sc products-01 style-04 light swin-vetical-slider">
                                

                                <div >
                                <div className="row">

                                <div className="col-md-9 d-flex">
                               
                                {/* <img src="https://cdn.iconscout.com/icon/free/png-512/non-veg-food-1851631-1569356.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />  */}
                                {
                                  dish_list.dish_type == "Veg" ?
                                  <img src="../assets/images/icon/vag.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                  :<img src="../assets/images/icon/non-vag-new.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                }
                                  <p className="dish_name" style={{float:'left',marginRight:'5px'}}><span className="dish_line_height">{dish_list.dish_name}</span>  <span className="line_height10"> <span className="kcal_g">{dish_list.dish_KiloCalories == null ? "" : <span>({dish_list.dish_KiloCalories} Kcal) / ({dish_list.dish_ServingSize}{dish_list.dish_ServedIn})</span> }</span> 
                                  {
                                  dish_list.dish_indicator1 == null ? null :<div className="indicator_icon"> <span><img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${dish_list.dish_indicator1}`} className="dish_icon indicator_img" id="new_tab" style={{marginLeft:'5px',marginTop:'0px'}} /> </span>  </div>
                                }
                                
                                {
                                  dish_list.dish_indicator2 == null ? null :<div className="indicator_icon"><span><img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${dish_list.dish_indicator2}`} className="dish_icon" style={{marginLeft:'5px',marginTop:'0px'}} /></span>  </div>
                                }
                                {
                                  dish_list.dish_indicator3 == null ? null :<div className="indicator_icon"><span><img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${dish_list.dish_indicator3}`} className="dish_icon" style={{marginLeft:'5px',marginTop:'0px'}} /></span>  </div>
                                }
                                </span>
                                {/* <div className="indicator_icon_text">tootip</div>  */}
                                </p>
                                
                                <p className="item-right dish_name text-center">{dish_list.dish_price == null ? null : <span className="price woocommerce-Price-amount amount">₹{dish_list.dish_price}</span>}</p>
                                </div>
                                 <div class="col-md-2 col-2 p-0 m-auto">
                                {dish_list.dish_KiloCalories == null ? "" : <Link class="read_more_btn" to={{ pathname: "/Nutrition", dish_name:dish_list.dish_name,serving_per_container: dish_list.dish_ServingPerContainer, counter_Servedcup: dish_list.dish_ServedCup,
                                                                             counter_Servings: dish_list.dish_ServingSize, counter_Servedi: dish_list.dish_ServedIn, counter_KiloCa: dish_list.dish_KiloCalories,
                                                                              counter_TransFatval: dish_list.dish_TotalFatValue, counter_TransFatper: dish_list.dish_TransFatPercent,counter_SaturatedFatper:dish_list.dish_SaturatedFatPercent,
                                                                              counter_SaturatedFatval:dish_list.dish_SaturatedFatValue,counter_TotalFatval:dish_list.dish_TotalFatValue,counter_TotalFatper:dish_list.dish_TotalFatPercent,
                                                                              counter_CholesterolVal:dish_list.dish_CholesterolValue,counter_CholesterolPer:dish_list.dish_CholesterolPercent,counter_SodiumVal:dish_list.dish_SodiumValue,
                                                                              counter_SodiumPer:dish_list.dish_SodiumPercent,counter_TotalCarbohydrateVal:dish_list.dish_TotalCarbohydrateValue,counter_TotalCarbohydratePer:dish_list.dish_TotalCarbohydratePercent,
                                                                              counter_DietaryFiberVal:dish_list.dish_DietaryFiberValue,counter_DietaryFiberPer:dish_list.dish_DietaryFiberPercent,counter_Sugar:dish_list.dish_Sugars,
                                                                              counter_AddedSugar:dish_list.dish_AddedSugars,counter_AddedSugarsPer:dish_list.dish_AddedSugarsPercent,counter_Protei:dish_list.dish_Protein,counter_VitaminAVal:dish_list.dish_VitaminAValue,
                                                                              counter_VitaminAPer:dish_list.dish_VitaminAPercent,counter_IronPer:dish_list.dish_IronPercent,counter_IronVal:dish_list.dish_IronValue,
                                                                              dish_CalciumPercent:dish_list.counter_CalciumPer,counter_CalciumVal:dish_list.dish_CalciumValue,
                                                                              counter_PotassiumGVal:dish_list.dish_PotassiumGValue,counter_PotassiumPer:dish_list.dish_PotassiumPercent}}>Read More</Link>}
                                  
                                  </div>
                                </div>                                
                                </div>
            
                              </div>
                              <div className="des" style={{lineHeight:'0.1'}}>{dish_list.dish_description}</div>
                            </div>
                               
                             )
                            })
                          }
                          </div>
                        </div>
                        </div>
                      )
                    })
                  }
                  </div>
                )
              })
            }
          </div>
        )
      })
     }
  </div>
  
  <div id="Wednesday" className="tab-content ">
  {
      counter_data.filter(mydata=> mydata.name == "Wednesday" ).map(mydata=>{
        const category_list = mydata.categories;
        return(
          <div className="text-left">
            {
              category_list.map((supercat_name)=>{
                const sub_catategory = supercat_name.subcategories
                
                return(
                  <div style={{padding:'10px',marginTop:'20px'}} >
                  <h1 style={{color:'gray',textAlign:'center'}}>{supercat_name.supercatname}</h1>
                  {
                    sub_catategory.map((sub_cat)=>{
                      const dishes = sub_cat.category_dishes;
                      return(
                        <div style={{background:'#fff',padding:'10px'}}>
                         {
                            sub_cat.subcatname == "NA" ?  <h3></h3> : <h3 className="sub_cat_heading">{sub_cat.subcatname}</h3> 
                          }
                        <div className="container">
                        <div className="row">
                          {
                            dishes.filter(dish_list=>dish_list.counter_heading== 3).map((dish_list,index)=>{
                             return(
                               
                              <div className="col-lg-6 col-md-6" style={{marginBottom:'20px'}}>                
                              <div className="swin-sc products-01 style-04 light swin-vetical-slider">
                                

                                <div >
                                <div className="row">

                                <div className="col-md-9 d-flex">
                               
                                {/* <img src="https://cdn.iconscout.com/icon/free/png-512/non-veg-food-1851631-1569356.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />  */}
                                {
                                  dish_list.dish_type == "Veg" ?
                                  <img src="../assets/images/icon/vag.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                  :<img src="../assets/images/icon/non-vag-new.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                }
                              <p className="dish_name" style={{float:'left',marginRight:'5px'}}><span className="dish_line_height">{dish_list.dish_name}</span>  <span className="line_height10"> <span className="kcal_g">{dish_list.dish_KiloCalories == null ? "" : <span>({dish_list.dish_KiloCalories} Kcal) / ({dish_list.dish_ServingSize}{dish_list.dish_ServedIn})</span> }</span> 
                                  {
                                  dish_list.dish_indicator1 == null ? null :<div className="indicator_icon"> <span><img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${dish_list.dish_indicator1}`} className="dish_icon indicator_img" id="new_tab" style={{marginLeft:'5px',marginTop:'0px'}} /> </span>  </div>
                                }
                                
                                {
                                  dish_list.dish_indicator2 == null ? null :<div className="indicator_icon"><span><img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${dish_list.dish_indicator2}`} className="dish_icon" style={{marginLeft:'5px',marginTop:'0px'}} /></span>  </div>
                                }
                                {
                                  dish_list.dish_indicator3 == null ? null :<div className="indicator_icon"><span><img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${dish_list.dish_indicator3}`} className="dish_icon" style={{marginLeft:'5px',marginTop:'0px'}} /></span>  </div>
                                }
                                </span>
                                {/* <div className="indicator_icon_text">tootip</div>  */}
                                </p>
                                
                                <p className="item-right dish_name text-center">{dish_list.dish_price == null ? null : <span className="price woocommerce-Price-amount amount">₹{dish_list.dish_price}</span>}</p>
                                </div>
                                 <div class="col-md-2 col-2 p-0 m-auto">
                                {dish_list.dish_KiloCalories == null ? "" : <Link class="read_more_btn" to={{ pathname: "/Nutrition", dish_name:dish_list.dish_name,serving_per_container: dish_list.dish_ServingPerContainer, counter_Servedcup: dish_list.dish_ServedCup,
                                                                             counter_Servings: dish_list.dish_ServingSize, counter_Servedi: dish_list.dish_ServedIn, counter_KiloCa: dish_list.dish_KiloCalories,
                                                                              counter_TransFatval: dish_list.dish_TotalFatValue, counter_TransFatper: dish_list.dish_TransFatPercent,counter_SaturatedFatper:dish_list.dish_SaturatedFatPercent,
                                                                              counter_SaturatedFatval:dish_list.dish_SaturatedFatValue,counter_TotalFatval:dish_list.dish_TotalFatValue,counter_TotalFatper:dish_list.dish_TotalFatPercent,
                                                                              counter_CholesterolVal:dish_list.dish_CholesterolValue,counter_CholesterolPer:dish_list.dish_CholesterolPercent,counter_SodiumVal:dish_list.dish_SodiumValue,
                                                                              counter_SodiumPer:dish_list.dish_SodiumPercent,counter_TotalCarbohydrateVal:dish_list.dish_TotalCarbohydrateValue,counter_TotalCarbohydratePer:dish_list.dish_TotalCarbohydratePercent,
                                                                              counter_DietaryFiberVal:dish_list.dish_DietaryFiberValue,counter_DietaryFiberPer:dish_list.dish_DietaryFiberPercent,counter_Sugar:dish_list.dish_Sugars,
                                                                              counter_AddedSugar:dish_list.dish_AddedSugars,counter_AddedSugarsPer:dish_list.dish_AddedSugarsPercent,counter_Protei:dish_list.dish_Protein,counter_VitaminAVal:dish_list.dish_VitaminAValue,
                                                                              counter_VitaminAPer:dish_list.dish_VitaminAPercent,counter_IronPer:dish_list.dish_IronPercent,counter_IronVal:dish_list.dish_IronValue,
                                                                              dish_CalciumPercent:dish_list.counter_CalciumPer,counter_CalciumVal:dish_list.dish_CalciumValue,
                                                                              counter_PotassiumGVal:dish_list.dish_PotassiumGValue,counter_PotassiumPer:dish_list.dish_PotassiumPercent}}>Read More</Link>}
                                  
                                  </div>
                                </div>                                
                                </div>

                              </div>
                              <div className="des" style={{lineHeight:'0.1'}}>{dish_list.dish_description}</div>
                            </div>
                               
                             )
                            })
                          }
                          </div>
                        </div>
                        </div>
                      )
                    })
                  }
                  </div>
                )
              })
            }
          </div>
        )
      })
     }
  </div>

  <div id="Thursday" className="tab-content ">
    
  {
      counter_data.filter(mydata=> mydata.name == "Thursday" ).map(mydata=>{
        const category_list = mydata.categories;
        return(
          <div className="text-left">
            {
              category_list.map((supercat_name)=>{
                const sub_catategory = supercat_name.subcategories
                
                return(
                  <div style={{padding:'10px',marginTop:'20px'}} >
                  <h1 style={{color:'gray',textAlign:'center'}}>{supercat_name.supercatname}</h1>
                  {
                    sub_catategory.map((sub_cat)=>{
                      const dishes = sub_cat.category_dishes;
                      return(
                        <div style={{background:'#fff',padding:'10px'}}>
                          {
                            sub_cat.subcatname == "NA" ?  <h3></h3> : <h3 className="sub_cat_heading">{sub_cat.subcatname}</h3> 
                          }
                        <div className="container">
                        <div className="row">
                          {
                            dishes.filter(dish_list=>dish_list.counter_heading== 4).map((dish_list,index)=>{
                             return(
                               
                              <div className="col-lg-6 col-md-6" style={{marginBottom:'20px'}}>                
                              <div className="swin-sc products-01 style-04 light swin-vetical-slider">
                                

                                <div >
                                <div className="row">

                                <div className="col-md-9 d-flex">
                               
                                {/* <img src="https://cdn.iconscout.com/icon/free/png-512/non-veg-food-1851631-1569356.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />  */}
                                {
                                  dish_list.dish_type == "Veg" ?
                                  <img src="../assets/images/icon/vag.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                  :<img src="../assets/images/icon/non-vag-new.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                }
                                 <p className="dish_name" style={{float:'left',marginRight:'5px'}}><span className="dish_line_height">{dish_list.dish_name}</span>  <span className="line_height10"> <span className="kcal_g">{dish_list.dish_KiloCalories == null ? "" : <span>({dish_list.dish_KiloCalories} Kcal) / ({dish_list.dish_ServingSize}{dish_list.dish_ServedIn})</span> }</span> 
                                  {
                                  dish_list.dish_indicator1 == null ? null :<div className="indicator_icon"> <span><img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${dish_list.dish_indicator1}`} className="dish_icon indicator_img" id="new_tab" style={{marginLeft:'5px',marginTop:'0px'}} /> </span>  </div>
                                }
                                
                                {
                                  dish_list.dish_indicator2 == null ? null :<div className="indicator_icon"><span><img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${dish_list.dish_indicator2}`} className="dish_icon" style={{marginLeft:'5px',marginTop:'0px'}} /></span>  </div>
                                }
                                {
                                  dish_list.dish_indicator3 == null ? null :<div className="indicator_icon"><span><img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${dish_list.dish_indicator3}`} className="dish_icon" style={{marginLeft:'5px',marginTop:'0px'}} /></span>  </div>
                                }
                                </span>
                                {/* <div className="indicator_icon_text">tootip</div>  */}
                                </p>
                                
                                <p className="item-right dish_name text-center">{dish_list.dish_price == null ? null : <span className="price woocommerce-Price-amount amount">₹{dish_list.dish_price}</span>}</p>
                                </div>
                                 <div class="col-md-2 col-2 p-0 m-auto">
                                {dish_list.dish_KiloCalories == null ? "" : <Link class="read_more_btn" to={{ pathname: "/Nutrition", dish_name:dish_list.dish_name,serving_per_container: dish_list.dish_ServingPerContainer, counter_Servedcup: dish_list.dish_ServedCup,
                                                                             counter_Servings: dish_list.dish_ServingSize, counter_Servedi: dish_list.dish_ServedIn, counter_KiloCa: dish_list.dish_KiloCalories,
                                                                              counter_TransFatval: dish_list.dish_TotalFatValue, counter_TransFatper: dish_list.dish_TransFatPercent,counter_SaturatedFatper:dish_list.dish_SaturatedFatPercent,
                                                                              counter_SaturatedFatval:dish_list.dish_SaturatedFatValue,counter_TotalFatval:dish_list.dish_TotalFatValue,counter_TotalFatper:dish_list.dish_TotalFatPercent,
                                                                              counter_CholesterolVal:dish_list.dish_CholesterolValue,counter_CholesterolPer:dish_list.dish_CholesterolPercent,counter_SodiumVal:dish_list.dish_SodiumValue,
                                                                              counter_SodiumPer:dish_list.dish_SodiumPercent,counter_TotalCarbohydrateVal:dish_list.dish_TotalCarbohydrateValue,counter_TotalCarbohydratePer:dish_list.dish_TotalCarbohydratePercent,
                                                                              counter_DietaryFiberVal:dish_list.dish_DietaryFiberValue,counter_DietaryFiberPer:dish_list.dish_DietaryFiberPercent,counter_Sugar:dish_list.dish_Sugars,
                                                                              counter_AddedSugar:dish_list.dish_AddedSugars,counter_AddedSugarsPer:dish_list.dish_AddedSugarsPercent,counter_Protei:dish_list.dish_Protein,counter_VitaminAVal:dish_list.dish_VitaminAValue,
                                                                              counter_VitaminAPer:dish_list.dish_VitaminAPercent,counter_IronPer:dish_list.dish_IronPercent,counter_IronVal:dish_list.dish_IronValue,
                                                                              dish_CalciumPercent:dish_list.counter_CalciumPer,counter_CalciumVal:dish_list.dish_CalciumValue,
                                                                              counter_PotassiumGVal:dish_list.dish_PotassiumGValue,counter_PotassiumPer:dish_list.dish_PotassiumPercent}}>Read More</Link>}
                                  
                                  </div>
                                </div>                                
                                </div>
      
                              </div>
                              <div className="des" style={{lineHeight:'0.1'}}>{dish_list.dish_description}</div>
                            </div>
                               
                             )
                            })
                          }
                          </div>
                        </div>
                        </div>
                      )
                    })
                  }
                  </div>
                )
              })
            }
          </div>
        )
      })
     }
  </div>
  
  <div id="Friday" className="tab-content ">
  {
      counter_data.filter(mydata=> mydata.name == "Friday" ).map(mydata=>{
        const category_list = mydata.categories;
        return(
          <div className="text-left">
            {
              category_list.map((supercat_name)=>{
                const sub_catategory = supercat_name.subcategories
                
                return(
                  <div style={{padding:'10px',marginTop:'20px'}} >
                  <h1 style={{color:'gray',textAlign:'center'}}>{supercat_name.supercatname}</h1>
                  {
                    sub_catategory.map((sub_cat)=>{
                      const dishes = sub_cat.category_dishes;
                      return(
                        <div style={{background:'#fff',padding:'10px'}}>
                          {
                            sub_cat.subcatname == "NA" ?  <h3></h3> : <h3 className="sub_cat_heading">{sub_cat.subcatname}</h3> 
                          }
                        <div className="container">
                        <div className="row">
                          {
                            dishes.filter(dish_list=>dish_list.counter_heading== 5).map((dish_list,index)=>{
                             return(
                               
                              <div className="col-lg-6 col-md-6" style={{marginBottom:'20px'}}>                
                              <div className="swin-sc products-01 style-04 light swin-vetical-slider">
                                

                                <div >
                                <div className="row">

                                <div className="col-md-9 d-flex">
                               
                                {/* <img src="https://cdn.iconscout.com/icon/free/png-512/non-veg-food-1851631-1569356.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />  */}
                                {
                                  dish_list.dish_type == "Veg" ?
                                  <img src="../assets/images/icon/vag.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                  :<img src="../assets/images/icon/non-vag-new.png" className="dish_icon" style={{float:'left',marginRight:'5px'}} />
                                }
                             <p className="dish_name" style={{float:'left',marginRight:'5px'}}><span className="dish_line_height">{dish_list.dish_name}</span>  <span className="line_height10"> <span className="kcal_g">{dish_list.dish_KiloCalories == null ? "" : <span>({dish_list.dish_KiloCalories} Kcal) / ({dish_list.dish_ServingSize}{dish_list.dish_ServedIn})</span> }</span> 
                                  {
                                  dish_list.dish_indicator1 == null ? null :<div className="indicator_icon"> <span><img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${dish_list.dish_indicator1}`} className="dish_icon indicator_img" id="new_tab" style={{marginLeft:'5px',marginTop:'0px'}} /> </span>  </div>
                                }
                                
                                {
                                  dish_list.dish_indicator2 == null ? null :<div className="indicator_icon"><span><img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${dish_list.dish_indicator2}`} className="dish_icon" style={{marginLeft:'5px',marginTop:'0px'}} /></span>  </div>
                                }
                                {
                                  dish_list.dish_indicator3 == null ? null :<div className="indicator_icon"><span><img src={`https://foodweb-pugapoo.ksoftpl.com/foodweb-punecafe/public/uploads/${dish_list.dish_indicator3}`} className="dish_icon" style={{marginLeft:'5px',marginTop:'0px'}} /></span>  </div>
                                }
                                </span>
                                {/* <div className="indicator_icon_text">tootip</div>  */}
                                </p>
                                
                                <p className="item-right dish_name text-center">{dish_list.dish_price == null ? null : <span className="price woocommerce-Price-amount amount">₹{dish_list.dish_price}</span>}</p>
                                </div>
                                 <div class="col-md-2 col-2 p-0 m-auto">
                                {dish_list.dish_KiloCalories == null ? "" : <Link class="read_more_btn" to={{ pathname: "/Nutrition", dish_name:dish_list.dish_name,serving_per_container: dish_list.dish_ServingPerContainer, counter_Servedcup: dish_list.dish_ServedCup,
                                                                             counter_Servings: dish_list.dish_ServingSize, counter_Servedi: dish_list.dish_ServedIn, counter_KiloCa: dish_list.dish_KiloCalories,
                                                                              counter_TransFatval: dish_list.dish_TotalFatValue, counter_TransFatper: dish_list.dish_TransFatPercent,counter_SaturatedFatper:dish_list.dish_SaturatedFatPercent,
                                                                              counter_SaturatedFatval:dish_list.dish_SaturatedFatValue,counter_TotalFatval:dish_list.dish_TotalFatValue,counter_TotalFatper:dish_list.dish_TotalFatPercent,
                                                                              counter_CholesterolVal:dish_list.dish_CholesterolValue,counter_CholesterolPer:dish_list.dish_CholesterolPercent,counter_SodiumVal:dish_list.dish_SodiumValue,
                                                                              counter_SodiumPer:dish_list.dish_SodiumPercent,counter_TotalCarbohydrateVal:dish_list.dish_TotalCarbohydrateValue,counter_TotalCarbohydratePer:dish_list.dish_TotalCarbohydratePercent,
                                                                              counter_DietaryFiberVal:dish_list.dish_DietaryFiberValue,counter_DietaryFiberPer:dish_list.dish_DietaryFiberPercent,counter_Sugar:dish_list.dish_Sugars,
                                                                              counter_AddedSugar:dish_list.dish_AddedSugars,counter_AddedSugarsPer:dish_list.dish_AddedSugarsPercent,counter_Protei:dish_list.dish_Protein,counter_VitaminAVal:dish_list.dish_VitaminAValue,
                                                                              counter_VitaminAPer:dish_list.dish_VitaminAPercent,counter_IronPer:dish_list.dish_IronPercent,counter_IronVal:dish_list.dish_IronValue,
                                                                              dish_CalciumPercent:dish_list.counter_CalciumPer,counter_CalciumVal:dish_list.dish_CalciumValue,
                                                                              counter_PotassiumGVal:dish_list.dish_PotassiumGValue,counter_PotassiumPer:dish_list.dish_PotassiumPercent}}>Read More</Link>}
                                  
                                  </div>
                                </div>                                
                                </div>

                              </div>
                              <div className="des" style={{lineHeight:'0.1'}}>{dish_list.dish_description}</div>
                            </div>
                               
                             )
                            })
                          }
                          </div>
                        </div>
                        </div>
                      )
                    })
                  }
                  </div>
                )
              })
            }
          </div>
        )
      })
     }
  </div>

  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img src="../assets/images/nuts_value.png" alt="" className="model_img" />
      </div>
      
    </div>
  </div>
</div>
  
 
 

</div>
  </section> 
        </div>
      </div>

</section>


      }




          
   {/* <section  className="about-us-session padding-top-100 padding-bottom-100 container">
<div className="container">
  <ul className="tabs">
    <li className="tab-link current" data-tab="tab-1">Tab One</li>
    <li className="tab-link" data-tab="tab-2">Tab Two</li>
    <li className="tab-link" data-tab="tab-3">Tab Three</li>
    <li className="tab-link" data-tab="tab-4">Tab Four</li>
    <li className="tab-link" data-tab="tab-5">Tab Five</li>

  </ul>
  <div id="tab-1" className="tab-content current">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
  </div>
  <div id="tab-2" className="tab-content">
    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
  </div>
  <div id="tab-3" className="tab-content">
    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
  </div>
  <div id="tab-4" className="tab-content">
    Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
  </div>
  <div id="tab-5" className="tab-content">
    5o eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
  </div>
</div>
  </section> */}

{/* enter old code */}
            </>
        )
    }
}

export default CounterMenu;